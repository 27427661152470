import { render, staticRenderFns } from "./agentAssessment.vue?vue&type=template&id=1185e7e6&scoped=true"
import script from "./agentAssessment.vue?vue&type=script&lang=js"
export * from "./agentAssessment.vue?vue&type=script&lang=js"
import style0 from "./agentAssessment.vue?vue&type=style&index=0&id=1185e7e6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1185e7e6",
  null
  
)

component.options.__file = "agentAssessment.vue"
export default component.exports