<template>
    <div class="bgblue uMange">
        <el-card class="box-card" style="margin-right: 7px;">
            <el-row>
                <el-form label-width="80px">
                    <el-col :span="6">
                        <el-form-item label="合伙人姓名:">
                            <el-input v-model="queryInfo.condition.cooperateName" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="合伙人手机:">
                            <el-input v-model="queryInfo.condition.cooperatePhone" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="任务编号:">
                            <el-input v-model="queryInfo.condition.taskNumber" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="考核月份:">
                            <el-input v-model="queryInfo.condition.assessmentMonth" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="合伙人类型:">
                            <el-select v-model="queryInfo.condition.typeCooperation" placeholder="请选择"
                                style="width:100%" clearable>
                                <el-option v-for="item in typeCooperationList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <div v-show="packUpOpen">
                        <el-col :span="6">
                            <el-form-item label="业务员名称:">
                                <el-select v-model="queryInfo.condition.salesmanName" placeholder="请选择"
                                    style="width:100%" clearable>
                                    <el-option v-for="item in salesmanNameList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="数据来源:">
                                <el-input v-model="queryInfo.condition.dataSource" placeholder="请输入" clearable />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="状态:">
                                <el-input v-model="queryInfo.condition.status" placeholder="请输入" clearable />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="计划提货量:">
                                <el-input v-model="queryInfo.condition.plannedUpvolume" placeholder="请输入" clearable />
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">
                            <el-form-item label="大区:">
                                <el-input v-model="queryInfo.condition.region" placeholder="请输入" clearable />
                            </el-form-item>
                        </el-col>
                    </div>
                    <el-col :span="6">
                        <el-form-item label="更新时间:">
                            <el-date-picker style="width: 100%;" v-model="queryInfo.condition.updateTime"
                                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="业务员工号:">
                            <el-input v-model="queryInfo.condition.salesmanCode" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="确认人:">
                            <el-select v-model="queryInfo.condition.querenName" placeholder="请选择" style="width:100%"
                                clearable>
                                <el-option v-for="item in querenNameList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="所属分公司:">
                            <el-input v-model="queryInfo.condition.affiliation" placeholder="请输入" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-button @click="upOrOpen" type="text"><i
                                :class="packUpOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>{{ packUpOpen ? '收起'
                                    : '展开' }}</el-button>
                        <el-button type="primary" icon="el-icon-search" @click="toSearch">查询</el-button>
                        <el-button icon="el-icon-refresh" @click="toReset">重置</el-button>
                    </el-col>
                </el-form>
            </el-row>
        </el-card>
        <el-card class="box-card" style="margin: 7px 7px 7px 0;">
            <el-row style="margin: 10px 0;">
                <el-col>
                    <el-button type="primary" icon="el-icon-plus" @click="toAdd()">新增</el-button>
                    <el-button type="primary" plain icon="el-icon-delete">作废</el-button>
                    <el-button type="primary" plain class="el-icon-download">导出</el-button>
                </el-col>
            </el-row>
            <el-table ref="multipleTable" class="fontSize-table" :row-style="{ height: '20px' }"
            :cell-style="{ padding: '0px' }" :data="tableData" tooltip-effect="dark" style="width: 100%;margin-top: 0;"
                height="calc(100vh - 370px)" border>
                <el-table-column label="操作" fixed width="100" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" class="btn-blue" @click="toShowLog(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
                <el-table-column type="selection" align="right" width="55">
                </el-table-column>
                <el-table-column prop="assessmentMonth" label="考核月份" width="90" align="center">
                </el-table-column>
                <el-table-column prop="typeAssessment" label="考核类型" align="center">
                </el-table-column>
                <el-table-column prop="cooperateName" label="合伙人名称" min-width="100px" align="center">
                </el-table-column>
                <el-table-column prop="cooperatePhone" label="合伙人手机" min-width="120px" align="center">
                </el-table-column>
                <el-table-column prop="plannedUpvolume" label="提货量" min-width="90px" align="center">
                </el-table-column>
                <el-table-column prop="fees" label="费用标准" align="center" min-width="90px">
                </el-table-column>
                <el-table-column prop="additionalBonus" label="额外费用奖励" width="120" align="center">
                </el-table-column>
                <el-table-column prop="dataSource" label="数据来源" min-width="100" align="center">
                </el-table-column>
                <el-table-column prop="cooperateName" label="代理商名称" min-width="100" align="center">
                </el-table-column>
                <el-table-column prop="salesmanName" label="业务员" align="center" min-width="100px">
                </el-table-column>
                <el-table-column prop="affiliation" label="所属省公司" width="120" align="center">
                </el-table-column>
                <el-table-column prop="region" label="大区" min-width="90" align="center">
                </el-table-column>
                <el-table-column prop="status" label="状态" min-width="90" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 0" type="warning">待处理</el-tag>
                        <el-tag v-else-if="scope.row.status == 1">已处理</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="taskNumber" label="任务编号" align="center" min-width="130px">
                </el-table-column>
                <el-table-column prop="createdname" label="创建人" width="120" align="center">
                </el-table-column>
                <el-table-column prop="createdtime" label="创建时间" min-width="150" align="center">
                </el-table-column>
                <el-table-column prop="updatename" label="更新人" min-width="160" align="center">
                </el-table-column>
                <el-table-column prop="updatetime" label="更新时间" min-width="150" align="center">
                </el-table-column>
                <el-table-column prop="querenName" label="确认人" align="center">
                </el-table-column>
                <el-table-column prop="querenTime" label="确认时间" min-width="150" align="center">
                </el-table-column>
            </el-table>
            <el-pagination :row-style="{ height: '20px' }" :cell-style="{ padding: '0px' }" class="flex"
                style="justify-content: flex-end" background @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :current-page="queryInfo.currPage" :page-sizes="[10, 20, 50, 100]"
                :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </el-card>
        <!-- 新增 -->
        <add-tasks ref="add"></add-tasks>
    </div>
</template>

<script>
import addTasks from './dialog/addMonthlyTasks'
export default {
    components: { addTasks },
    data() {
        return {
            tableData: [],

            // 分页查询
            queryInfo: {
                pageSize: 10,
                pageCount: 1,
                currPage: 1,
                condition: {
                    businessType: "",
                    accountItemName: ""
                }
            },
            total: 0,
            typeCooperationList: [
                { value: 1, label: '合伙人' },
                { value: 2, label: '分销商' },
                { value: 3, label: '安装商' },
                { value: 4, label: '网络经理' },
                { value: 5, label: '合伙人总数' },
            ],
            salesmanNameList: [], // 业务员名称
            querenNameList: [], // 确认人
            statusOptions: [
                {
                    value: "0",
                    label: "未支付"
                },
                {
                    value: "1",
                    label: "已支付"
                },
                {
                    value: "2",
                    label: "已退款"
                }
            ],
            packUpOpen: false, // 收起展开
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            // this.queryInfo.condition.agentCode = JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode;
            this.$http.post("/houseAssessmentTasks/list", this.queryInfo).then(res => {
                if (res.data.code == 200) {
                    this.total = res.data.data.count;
                    this.tableData = res.data.data.data;
                }
            });
        },
        upOrOpen() {
            this.packUpOpen = !this.packUpOpen
        },
        // 新增
        toAdd() {
            this.$refs.add.init()
        },
        // 编辑
        toShowLog(row) {
        },
        toSearch() {
            this.getList();
        },
        // 重置
        toReset() {
            this.createDate = [];
            this.initiateDate = [];
            this.agentInsureDate = [];
            this.paymentApplyDate = [];
            this.paymentFinishDate = [];
            this.queryInfo.condition = {};
            this.getList();
        },
        // 修改页数大小
        handleSizeChange(val) {
            this.queryInfo.pageSize = val;
            this.getList();
        },

        // 获取当前页面
        handleCurrentChange(val) {
            this.queryInfo.currPage = val;
            this.getList();
        }
    }
};
</script>

<style lang="less" scoped>
.uMange {
    padding: 7px;

    .logInfoBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .logInfoItem {
            display: flex;
            gap: 10px;
        }

        .logInfoItem>div:first-child {
            width: 120px;
            font-weight: bold;
        }
    }

    .logHistory {
        font-size: 18px;
        font-weight: bold;
        margin: 20px 0;
    }

    .imgBox {
        //width: 148px;
        height: 148px;
        position: relative;
        //margin-bottom: 5px;
    }

    .imgBox:hover .img-icon {
        display: flex;
    }

    .img-icon {
        width: 148px;
        height: 148px;
        border-radius: 6px;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: rgba(0, 0, 0, 0.4);

        i {
            font-size: 26px;
            color: #fff;
        }
    }

    .avatar {
        display: block;
        width: 148px;
        height: 148px;
        border-radius: 6px;
    }

    /deep/ .el-table .cell {
        padding: 1px;
    }

    /deep/.fontSize-table {
        .el-button {
            height: 20px !important;
            padding: 1px 6px !important;
        }

        .el-tag {
            height: 16px;
            line-height: 16px;
        }

        font-size: 14px;

        .cell {
            line-height: 16px;
        }
    }

    /deep/ .cellNopadding {
        .cell {
            padding-left: 0 !important;
        }
    }


}
</style>