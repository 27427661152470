<template>
  <div class="box">
    <el-drawer
      title="新增月度任务"
      :visible.sync="drawer"
      direction="rtl"
      size="60%"
    >
      <el-form
        style="margin-right: 20px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item
          label="考核月份"
          label-width="150px"
          prop="assessmentMonth"
        >
          <el-date-picker
            v-model="ruleForm.assessmentMonth"
            style="width: 100%"
            type="month"
            placeholder="选择月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="考核类别"
          label-width="150px"
          prop="typeAssessment"
        >
          <el-select
            v-model="ruleForm.typeAssessment"
            style="width: 100%"
            placeholder="请选择活动区域"
          >
            <el-option label="合伙人" value="1"></el-option>
            <el-option label="分销商" value="2"></el-option>
            <el-option label="安装商" value="3"></el-option>
            <el-option label="网络经理" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="16">
            <el-form-item
              label="提货量"
              label-width="150px"
              prop="plannedUpvolume"
            >
              <el-input
                type="text"
                v-model="ruleForm.plannedUpvolume"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="margin: 5px 30px">
              <el-radio v-model="ruleForm.radio" label="1">块</el-radio>
              <el-radio v-model="ruleForm.radio" label="2">户</el-radio>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="费用标准" label-width="150px" prop="fees">
              <el-input type="text" v-model="ruleForm.fees"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <div style="margin: 5px 30px">
              <el-radio v-model="ruleForm.radio" label="1">元/块</el-radio>
              <el-radio v-model="ruleForm.radio" label="2">元/户</el-radio>
            </div>
          </el-col>
        </el-row>

        <!-- <el-form-item prop="delivery" label="1">
                    <el-checkbox-group v-model="ruleForm.type">
                        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                        <el-checkbox label="地推活动" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item> -->
        <el-form-item label="额外费用奖励" label-width="150px">
          <el-input type="text" v-model="ruleForm.additionalBonus"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="选择人员" label-width="150px" prop="desc">
              <el-input type="text" v-model="ruleForm.desc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              style="margin: 2px 0 0 10px"
              @click="submitForm('ruleForm')"
              >查询</el-button
            >
          </el-col>
          <el-col :span="9">
            <el-select
              v-model="ruleForm.PersonCode"
              style="width: 100%"
              placeholder="请选择活动区域"
              @change="changePersonCode"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="代理商" :value="1"></el-option>
              <el-option label="分销商对应" :value="2"></el-option>
              <el-option label="安装商" :value="3"></el-option>
              <el-option label="网络经理" :value="4"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="dataList">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="date"
          label="账号"
        ></el-table-column>
        <el-table-column
          property="date"
          label="代理商名称"
        ></el-table-column>
        <el-table-column
          property="date"
          label="手机号"
        ></el-table-column>
        <el-table-column
          property="name"
          label="岗位类型"
        ></el-table-column>
        <el-table-column
          property="address"
          label="所属商户"
        ></el-table-column>
      </el-table>
    </el-drawer>

    <el-button>保存</el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      ruleForm: {
        PersonCode:0
      },
      dataList: [],
      rules: {
        assessmentMonth: [
          { required: true, message: "请输入考核类型", trigger: "blur" },
        ],
        typeAssessment: [
          { required: true, message: "考核类别", trigger: "change" },
        ],
        plannedUpvolume: [
          { required: true, message: "请输入考核类型", trigger: "blur" },
        ],
      },
    };
  },
  created(){
    this.getData()
  },
  methods: {
    init() {
      this.drawer = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    getData(){
      this.$http.post("/houseAssessmentTasks/PersonnelList", this.ruleForm.PersonCode).then(res => {
        if(res.data.code===200){
          this.dataList = res.data.data
        }
        // console.log(res);
      })
    },
    changePersonCode(val){
      this.ruleForm.PersonCode = val;
       this.getData()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 50%;

  // /deep/ .el-form-item {
  //     margin-bottom: 16px !important;
  // }
}
</style>
